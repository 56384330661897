import { Divider, Select } from "antd";
import { Component } from "react";
import { schools_grades, schools_list } from "../installment-flow/schoolsList";
import { connect } from "react-redux";
import {
  goToStep,
  setChildrenInfo,
  setSchoolInfo,
} from "../../redux/actions/authActions";

class SchoolInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      children: [
        {
          grade: null,
          path: null,
          gender: "male",
        },
      ],
      childIncomplete: false,
    };
  }
  changePath = (value, index) => {
    const { children } = this.state;
    children[index].path = value;
    this.setState({ children });
  };
  changeGender = (e, index) => {
    const { children } = this.state;
    console.log(e);
    children[index].gender = e;
    this.setState({ children });
  };
  changeGrade = (value, index) => {
    const { children } = this.state;

    children[index].grade = value;
    this.setState({ children });
  };
  addChildRow = () => {
    const { children } = this.state;
    const length = children.length;
    if (children[length - 1].path && children[length - 1].grade) {
      console.log("ADDED");
      this.setState({
        children: [...children, { path: null, path: null, gender: "male" }],
        childIncomplete: false,
      });
    } else {
      this.setState({
        childIncomplete: true,
      });
    }
  };
  saveAddedChildren = () => {
    if (this.state.school) {
      const no_of_children = this.state.children.length;

      const no_of_real_children = this.state.children?.filter((child) => {
        return child.gender !== null && child.grade != null;
      }).length;
      if (no_of_children > no_of_real_children) {
        this.setState({
          childIncomplete: true,
        });
      } else {
        this.props.setChildrenInfo(this.state.children);
      }
    } else {
      return;
    }
  };
  render() {
    return (
      <div className="application-form-blocks">
        <div className="blocks-inner">
          <div className="form-block">
            <p className="form-block-name absolute">بيانات المدرسة</p>
            <div className="form-fields">
              <div className="field">
                <div className="cform-label">اسم المدرسة</div>
                <Select
                  onChange={(school) => {
                    this.props.setSchoolInfo(school);
                    this.setState({ school });
                  }}
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  placeholder="قم بالبحث باسم المدرسة"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label?.toLowerCase() ?? "").includes(
                      input?.toLowerCase()
                    )
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={schools_list}
                />
              </div>
            </div>
          </div>
          <div className="form-block children-block">
            <p className="form-block-name absolute">بيانات الابناء</p>
            {this.state.children?.map((child, index) => {
              return (
                <div className="child-info" key={index}>
                  <div className="form-fields">
                    <div className="field">
                      <div className="cform-label">النوع</div>
                      <Select
                        onChange={(e) => {
                          this.changeGender(e, index);
                        }}
                        showSearch
                        value={child?.gender}
                        style={{
                          width: "100%",
                        }}
                        placeholder="نوع الطـالب"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.label?.toLowerCase() ?? "").includes(
                            input?.toLowerCase()
                          )
                        }
                        filterSort={(optionA, optionB) =>
                          (optionA?.label ?? "")
                            .toLowerCase()
                            .localeCompare((optionB?.label ?? "").toLowerCase())
                        }
                        options={[
                          { label: "أنثى", value: "female" },
                          { label: "ذكر", value: "male" },
                        ]}
                      />
                    </div>
                    <div className="field">
                      <div className="cform-label">المسار</div>
                      <Select
                        value={child?.path}
                        onChange={(e) => {
                          this.changePath(e, index);
                        }}
                        showSearch
                        style={{
                          width: "100%",
                        }}
                        placeholder="إختر المسار"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.label?.toLowerCase() ?? "").includes(
                            input?.toLowerCase()
                          )
                        }
                        filterSort={(optionA, optionB) =>
                          (optionA?.label ?? "")
                            .toLowerCase()
                            .localeCompare((optionB?.label ?? "").toLowerCase())
                        }
                        options={[{ label: "عام", value: "general" }]}
                      />
                    </div>
                    <div className="field">
                      <div className="cform-label"> السنة الدراسية</div>
                      <Select
                        showSearch
                        value={child?.grade}
                        style={{
                          width: "100%",
                        }}
                        onChange={(e) => {
                          this.changeGrade(e, index);
                        }}
                        placeholder="إختر المرحلة"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.label?.toLowerCase() ?? "").includes(
                            input?.toLowerCase()
                          )
                        }
                        filterSort={(optionA, optionB) =>
                          (optionA?.label ?? "")
                            .toLowerCase()
                            .localeCompare((optionB?.label ?? "").toLowerCase())
                        }
                        options={schools_grades}
                      />
                    </div>
                  </div>
                  {index + 1 < this.state.children?.length && (
                    <Divider
                      style={{
                        borderColor: "#000091",
                        margin: "10px auto",
                      }}
                    />
                  )}
                </div>
              );
            })}
            <p className="error red">
              {this.state.childIncomplete && "من فضلك ادخل الابن كاملة"}
            </p>
            <p
              className="add-child"
              onClick={() => {
                this.addChildRow();
              }}
            >
              إضافة ابن
            </p>
          </div>

          <button
            className="next-flow"
            onClick={() => {
              // this.props.setSchoolInfo({ name: "SSS" });
              // console.log(this.state.children)
              this.saveAddedChildren();
            }}
          >
            التالي
          </button>
        </div>
      </div>
    );
  }
}

// export default ;

const mapDispatchToProps = (dispatch) => ({
  setChildrenInfo: (info) => dispatch(setChildrenInfo(info)),
  goToStep: (step) => dispatch(goToStep(step)),
  setSchoolInfo: (info) => dispatch(setSchoolInfo(info)),
});
const mapStateToProps = (state) => {
  return {
    currentIndex: state.installmentReducer.stepIndex,
    parentInfo: state.installmentReducer.parentInfo,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SchoolInfo);
