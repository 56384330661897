import * as actions from "../constants";

const authState = {
  isLoggedIn: false,
  userInfo: null,
  authLoading: false,
  authError: null,
};

const authInfo = localStorage.getItem("authState")?JSON.parse(localStorage.getItem("authState")) : authState;
const authReducer = (state = authInfo, action) => {
  switch (action.type) {
    case actions.AUTHENTICATION_REQUEST:
      return {
        ...state,
        isLoggedIn: false,
        userInfo: null,
        authLoading: true,
      };

    case actions.AUTHENTICATION_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        userInfo: action.payload,
        authLoading: false,
      };
    case actions.AUTHENTICATION_FAILED:
      return {
        ...state,
        isLoggedIn: false,
        userInfo: null,
        authLoading: false,
        authError: action.payload,
      };
    case actions.LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        userInfo: null,
        authLoading: false,
        authError: null,
      };

    default:
      return state;
  }
};

export default authReducer;
