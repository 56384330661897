import { Component } from "react";
import "./application.css";
import { Progress } from "antd";
import ParentInfo from "./ApplicationFlow/ParentInfo";
import { connect } from "react-redux";
import SchoolInfo from "./ApplicationFlow/SchoolInfo";
import DoocumentsInfo from "./ApplicationFlow/DocumentsInfo";
import FeeCalculationStep from "./ApplicationFlow/FeeCalculationStep";
import flag_logo from "./../assets/kwlogo.png";
class ApplicationForm extends Component {
  constructor(props) {
    super(props);
  }
  state = {};
  render() {
    return (
      <div id="application-form" className="application">
        <div className="application-container">
          <div className="application-inner">
            <div className="application-header">
              <div className="header-content">
                <div className="app-wrapper">
                  <div className="header-grid">
                   <a href="/">
                   <div className="application-logo">
                      <div>
                        <a href="/">
                        <img
                          className="inline-block"
                          src={flag_logo}
                          width={50}
                          alt=""
                        />
                        </a>
                        <span className="inline-block"> اسم الموقع</span>
                      </div>

                      <div>
                        <p className="application-header-title">
                          التقدم بطلب تقسيط الرسوم الدراسية
                        </p>
                      </div>
                    </div>
                   </a>
                    <button className="next-flow">حفظ الطلب</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="application-steps">
              <div className="steps-header">
                <p className="pages">خطوة {this.props.currentIndex + 1} من 4</p>
                <div className="stepper-timeline">
                  {/* <p className="stepper-stage">الطلب: بيانات ولي الأمر</p> */}
                  {this.props.currentIndex === 0 && <p className="stepper-stage">الطلب: بيانات ولي الأمر</p>}
                  {this.props.currentIndex === 1 && <p className="stepper-stage">الطلب: بيانات الابناء</p>}
                  {this.props.currentIndex === 2 && <p className="stepper-stage">الطلب: رفع المرفقات</p>}
                  {this.props.currentIndex === 3 && <p className="stepper-stage">الطلب: حساب الاقساط والرسوم</p>}

                  <div className="steppers-bars">
                    <Progress
                      strokeLinecap="butt"
                      percent={100}
                      strokeColor={"#000091"}
                      showInfo={false}
                    />

                    <Progress
                      strokeLinecap="butt"
                      percent={this.props.currentIndex > 0 ? 100 : 0}
                      showInfo={false}
                      strokeColor={"#000091"}
                    />
                    <Progress
                      strokeLinecap="butt"
                      // percent={0}
                      percent={this.props.currentIndex > 1 ? 100 : 0}
                      strokeColor={"#000091"}
                      showInfo={false}
                    />
                    <Progress
                      strokeLinecap="butt"
                      // percent={0}
                      percent={this.props.currentIndex > 2 ? 100 : 0}
                      showInfo={false}
                      strokeColor={"#000091"}
                    />
                  </div>
                 

                  {this.props.currentIndex === 0 && (
                    <div className="stepper-desc">
                      <p>الخطوة القادمة : بيـانات الأبناء والمدرسة </p>
                    </div>
                  )}
                  {this.props.currentIndex === 1 && (
                    <div className="stepper-desc">
                      <p>الخطوة القادمة : رفع المرفقات </p>
                    </div>
                  )}

                  {this.props.currentIndex === 2 && (
                    <div className="stepper-desc">
                      <p>الخطوة القادمة : حساب الاقساط </p>
                    </div>
                  )}
                  {this.props.currentIndex === 0 && <ParentInfo />}
                  {this.props.currentIndex === 1 && <SchoolInfo />}
                  {this.props.currentIndex === 2 && <DoocumentsInfo />}
                  {this.props.currentIndex === 3 && <FeeCalculationStep />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    currentIndex: state.installmentReducer.stepIndex,
    parentInfo: state.installmentReducer.parentInfo,
  };
};
export default connect(mapStateToProps, null)(ApplicationForm);
