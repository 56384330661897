import {  useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

const AuthRoute = ({ children }) => {
  const auth = useSelector((state) => state.authReducer?.isLoggedIn);
  let location = useLocation();

  return (
    <>
      {auth ? (
        <Navigate to="/" replace state={{ from: location }} />
      ) : (
        <>{children}</>
      )}
    </>
  );
};

export default AuthRoute;
