import { Component } from "react";
import { Row, Col } from "antd";
import "./navigation.css";
import { CiMenuBurger, CiSearch } from "react-icons/ci";
import { FaUser } from "react-icons/fa";

class NavigationMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isScrolled: false,
      scrolling: "",
    };
  }

  handleScroll = () => {
    if (window.scrollY > 10) {
      this.setState({ isScrolled: true, scrolling: "scrolled" });
    } else {
      this.setState({ isScrolled: false, scrolling: "" });
    }
  };
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  render() {
    const { scrolling } = this.state;
    return (
      <div className={`navigation-menu ${scrolling}`}>
        <div className="inner-navigation">
          <div className={`navigation-wrapper app-wrapper`}>
            <Row justify={"space-between"} align="middle">
              <Col md={12}>
                <div className="logo">
                  <p className="logo-place"><a href="/">لوجـــو</a></p>
                </div>
              </Col>
              <Col md={12}>
                <Row justify={"end"} align="middle" gutter={35}>
                  <Col  md={8}>
                    <div className="search nav-item">
                      <p>
                        <span>بحث</span>
                        <span>
                          <CiSearch />
                        </span>
                      </p>
                    </div>
                  </Col>
                  <Col  md={8}>
                    <div className="navmenu nav-item">
                      <p>
                      <span>  قائمة</span>
                        <span>
                          <CiMenuBurger />
                        </span>
                      </p>
                    </div>
                  </Col>
                  <Col md={8}> 
                    <div className="myportal nav-item bg-orange">
                      <p>
                        <span>
                        تسجيل خروج
                        </span>
                        <span>
                          <FaUser />
                        </span>
                      </p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

export default NavigationMenu;
