import { Modal, Select } from "antd";
import React, { Component } from "react";
import { Spinner } from "react-bootstrap";
import { FcApproval } from "react-icons/fc";

class FeeCalculationStep extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      modal: false,
    };
  }
  sendApplication = () => {
    console.log();
    this.setState({
      loading: true,
    });
    setTimeout(() => {
      this.setState({
        loading: false,
        modal: true,
      });
    }, 2000);
  };
  render() {
    return (
      <>
        <div className="application-form-blocks">
          <div className="blocks-inner">
            <div className="form-block">
              <p className="form-block-name absolute">الرسوم والأقساط</p>
              <div className="form-fields">
                <div className="field">
                  <div className="cform-label"> عدد الاقساط</div>
                  <Select
                    showSearch
                    style={{
                      width: "100%",
                    }}
                    placeholder="اختر عدد الاقساط"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label?.toLowerCase() ?? "").includes(
                        input?.toLowerCase()
                      )
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                    options={[
                      { label: "6 شهور", value: 6 },
                      { label: "12 شهور", value: 12 },
                      { label: "24 شهر", value: 24 },
                    ]}
                  />
                </div>
              </div>
            </div>

            <button
              className="next-flow"
              onClick={() => this.sendApplication()}
            >
              {this.state.loading ? (
                // <span>
                <Spinner
                  className="icon"
                  animation="border"
                  role="status"
                  size="lg"
                />
              ) : (
                // </span>
                "إرسال"
              )}
            </button>
          </div>
        </div>
        <Modal
        
          open={this.state.modal}
          onCancel={() => {
            this.setState({
              modal: false,
            });
          }}
          footer={false}
          header={false}
        >
          <h5>
            <span style={{
              fontSize:"2rem"
            }}>

              <FcApproval/>
            </span>
            تم ارسال طلبك لادارة المدرسة وسيتم التواصل مع سيادتكم خلال ال 48
            ساعه القادمة

          </h5>
          <a href="/">العودة للرئيسية</a>
        </Modal>
      </>
    );
  }
}

export default FeeCalculationStep;
