import React, { Component } from 'react';
import "./footer.css"
import { FaFacebookSquare,FaLinkedin  } from "react-icons/fa";
import { BsTwitterX } from "react-icons/bs";
import { GrInstagram } from "react-icons/gr";

class Footer extends Component {
    constructor(props) {
        super(props);
    }
    state = {  }
    render() { 
        return ( <div className="footer-outer">
            <div className="inner-footer">
                <div className="footer-content">
                    <div className="footer-grid">
                        <div className="light-section">
                            <p className="footer-ad">
                            بخيارات دفع مرنة لاقصي حد
                            </p>
                            <h5>ابدا رحله تقسيط الرسوم الدراسية من خلال موقعنا</h5>
                            <button className="contactus-btn">تواصل معنا</button>
                        </div>
                        <div className="dark-section">
                            <div className="links-cols">
                                <div className="footer-col">
                                    <p className="footer-name">
                                        اسم الموقع
                                    </p>
                                    <div className="socials">
                                        <div className="social-icon"><FaFacebookSquare/></div>
                                        <div className="social-icon"><FaLinkedin/></div>
                                        <div className="social-icon"><BsTwitterX/></div>
                                        <div className="social-icon"><GrInstagram/></div>
                                    </div>
                                </div>
                                <div className="footer-col">
                                    <p> لينك تجريبي</p>
                                    <p> لينك تجريبي</p>
                                    <p> لينك تجريبي</p>
                                    <p> لينك تجريبي</p>
                                    <p> لينك تجريبي</p>
                                </div>
                                <div className="footer-col">
                                <p> لينك تجريبي</p>
                                    <p> لينك تجريبي</p>
                                    <p> لينك تجريبي</p>
                                    <p> لينك تجريبي</p>
                                    <p> لينك تجريبي</p>
                                </div>
                                <div className="footer-col">
                                <p> لينك تجريبي</p>
                                    <p> لينك تجريبي</p>
                                    <p> لينك تجريبي</p>
                                    <p> لينك تجريبي</p>
                                    <p> لينك تجريبي</p>
                                    <p> لينك تجريبي</p>
                                    <p> لينك تجريبي</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div> );
    }
}
 
export default Footer;