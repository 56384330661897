import { Component } from "react";
// import { FaFileUpload } from "react-icons/fa";
import { FaCloudUploadAlt } from "react-icons/fa";
import {
  goToStep,
  setChildrenInfo,
  setSchoolInfo,
  setDocumentInfoSrcs
} from "../../redux/actions/authActions";
import { connect } from "react-redux";

class DoocumentsInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      identityURLs: [],
      salaryURLs: [],
    };
  }
  uploadIdentityImages = (e) => {
    const files = e.target.files; // Access all selected files
    if (files.length > 0) {
      const identityURLs = Array.from(files).map((file) => {
        const src = URL.createObjectURL(file); // Create object URLs for preview
        console.log(src);
        return src;
      });
      console.log(identityURLs); // Log all file URLs
      this.setState({
        identityURLs: [...this.state.identityURLs, ...identityURLs],
      });
    } else {
      console.log("No files selected");
    }
  };
  uploadSalaryImages = (e) => {
    const files = e.target.files; // Access all selected files
    if (files.length > 0) {
      const salaryURLs = Array.from(files).map((file) => {
        const src = URL.createObjectURL(file); // Create object URLs for preview
        console.log(src);
        return src;
      });
      console.log(salaryURLs); // Log all file URLs
      this.setState({
        salaryURLs: [...this.state.salaryURLs, ...salaryURLs],
      });
    } else {
      console.log("No files selected");
    }
  };
  render() {
    return (
      <div className="application-form-blocks">
        <div className="blocks-inner">
          <div className="form-block">
            <p className="form-block-name absolute">الهوية الشخصيه</p>
            <div className="form-docs">
              <div className="docs">
                <div className="upload-wedgit relative">
                  <input
                    type="file"
                    className="absolute"
                    multiple
                    onChange={(e) => {
                      console.log(e);
                      this.uploadIdentityImages(e);
                    }}
                  />
                  <div className="absolute upload-icon">
                    <FaCloudUploadAlt />
                  </div>
                </div>
                {this.state.identityURLs?.map((image) => {
                  return (
                    <div
                      className="img"
                      style={{
                        backgroundImage: `url("${image}")`,
                      }}
                    ></div>
                  );
                })}
              </div>
              <div className="cform-label text-center">
                قم برفع صورة الهوية الشخصية , بامتدادات png , jpg, jpeg
              </div>
            </div>
          </div>
          <div className="form-block">
            <p className="form-block-name absolute">بيان دخل</p>
            <div className="form-docs">
             <div className="docs">
             <div className="upload-wedgit relative">
                <input
                  type="file"
                  className="absolute"
                  multiple
                  onChange={(e) => {
                    console.log(e);
                    this.uploadSalaryImages(e);
                  }}
                />
                <div className="absolute upload-icon">
                  <FaCloudUploadAlt />
                </div>
              </div>
              {this.state.salaryURLs?.map((image) => {
                  return (
                    <div
                      className="img"
                      style={{
                        backgroundImage: `url("${image}")`,
                      }}
                    ></div>
                  );
                })}
             </div>
              <div className="cform-label text-center">
                قم برفع صورة كشف او بيان دخل , بامتدادات png , jpg, jpeg
              </div>
            </div>
          </div>
          <button
            className="next-flow"
            onClick={() => {
              this.props.setDocumentInfoSrcs({
                identityDocs:this.state.identityURLs,
                salaryDocs:this.state.salaryURLs,
              })
              this.props.goToStep(3);
            }}
          >
            التالي
          </button>
        </div>
      </div>
    );
  }
}

// export default DoocumentsInfo;
const mapDispatchToProps = (dispatch) => ({
  setChildrenInfo: (info) => dispatch(setChildrenInfo(info)),
  goToStep: (step) => dispatch(goToStep(step)),
  setSchoolInfo: (info) => dispatch(setSchoolInfo(info)),
  setDocumentInfoSrcs:(info)=>dispatch(setDocumentInfoSrcs(info))
});
const mapStateToProps = (state) => {
  return {
    currentIndex: state.installmentReducer.stepIndex,
    parentInfo: state.installmentReducer.parentInfo,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DoocumentsInfo);
