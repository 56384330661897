import { Input } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { setParentInfo } from "../../redux/actions/authActions";

class ParentInfo extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    parent: {
      name: "",
      nid: "",
      address: "",
      email: "",
      phone: "",
      profession: "",
    },
  };

  handleChangeParent = (e, field) => {
    const { parent } = this.state;
    parent[field] = e.target.value;
    this.setState(
      {
        parent,
      },
      () => {
        console.log(this.state.parent);
      }
    );
  };
  saveParentInfo = () => {
    const { email, name, phone, nid, address, profession } = this.state.parent;
    if (
      email.length > 0 &&
      name.length > 0 &&
      phone.length > 0 &&
      nid.length > 0 &&
      address.length > 0 &&
      profession.length > 0
    ) {
      this.props.setParentInfo(this.state.parent);
    } else {
      return;
    }
  };
  render() {
    return (
      <div className="application-form-blocks">
        <div className="blocks-inner">
          <div className="form-block">
            <p className="form-block-name absolute">بيانات أساسية</p>
            <div className="form-fields">
              <div className="field">
                <div className="cform-label">اسم ولي الأمر كـاملا </div>
                <Input
                  size="large"
                  placeholder="حقل بيـنات"
                  onChange={(e) => {
                    this.handleChangeParent(e, "name");
                  }}
                />
              </div>

              <div className="field">
                <div className="cform-label"> رقم الهوية الشخصية</div>
                <Input
                  size="large"
                  placeholder="حقل بيـنات"
                  onChange={(e) => {
                    this.handleChangeParent(e, "nid");
                  }}
                />
              </div>
            </div>
          </div>
          <div className="form-block">
            <p className="form-block-name absolute">بيانات التواصل</p>
            <div className="form-fields">
              <div className="field">
                <div className="cform-label">عنوان الإقامة</div>
                <Input
                  size="large"
                  placeholder="حقل بيـنات"
                  onChange={(e) => {
                    this.handleChangeParent(e, "address");
                  }}
                />
              </div>

              <div className="field">
                <div className="cform-label"> البريد الالكتروني</div>
                <Input
                  size="large"
                  placeholder="حقل بيـنات"
                  onChange={(e) => {
                    this.handleChangeParent(e, "email");
                  }}
                />
              </div>
              <div className="field">
                <div className="cform-label"> رقم الجوال</div>
                <Input
                  size="large"
                  placeholder="حقل بيـنات"
                  onChange={(e) => {
                    this.handleChangeParent(e, "phone");
                  }}
                />
              </div>
            </div>
          </div>
          <div className="form-block">
            <p className="form-block-name absolute">بيانات إضافية</p>
            <div className="form-fields">
              <div className="field">
                <div className="cform-label"> المهنة</div>
                <Input
                  size="large"
                  placeholder="حقل بيـنات"
                  onChange={(e) => {
                    this.handleChangeParent(e, "profession");
                  }}
                />
              </div>
            </div>
          </div>
          <button
            className="next-flow"
            onClick={() => {
              this.saveParentInfo();
            }}
          >
            التالي
          </button>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setParentInfo: (info) => dispatch(setParentInfo(info)),
});
const mapStateToProps = (state) => {
  return {
    currentIndex: state.installmentReducer.stepIndex,
    parentInfo: state.installmentReducer.parentInfo,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ParentInfo);
