

export const ADD_BASIC_INFO_STEP = 'ADD_BASIC_INFO_STEP'


export const AUTHENTICATION_REQUEST = 'AUTHENTICATION_REQUEST'
export const AUTHENTICATION_SUCCESS = 'AUTHENTICATION_SUCCESS'
export const AUTHENTICATION_FAILED = 'AUTHENTICATION_FAILED'
export const LOGOUT = 'LOGOUT'

export const ADD_CHILDREN_INFO_STEP = 'ADD_CHILDREN_INFO_STEP'
export const GO_TO_NEXT_STEP = 'GO_TO_NEXT_STEP'
export const ADD_TAQSEET_DOCUMENT_STEO="ADD_TAQSEET_DOCUMENT_STEO"
export const GO_TO_STEP="GO_TO_STEP"



// new flow for school
export const ADD_PARENT_INFO_STEP = 'ADD_PARENT_INFO_STEP'
export const ADD_SCHOOL_INFO_STEP = 'ADD_SCHOOL_INFO_STEP'
export const UPLOAD_PARENT_DOCUMENTS_STEP = 'ADD_BASIC_INFO_STEP'



export const regionNames = new Intl.DisplayNames(["en"], { type: "region" });






export const returnCountryName=(country,city="")=>{

    let full_country;
    let full_city;

    if (country && country!=='null' && country!='undefined' && country?.length<5) {
        full_country= regionNames.of(country)
    }
    else{
        full_country= country
    }

    if(city && city!='null' && city!='undefined'){
        // full_city=`, ${city}`
        full_city=`, ${city?.charAt(0).toUpperCase() + city?.slice(1)}`
    }else{
        full_city=''
    }

    return `${full_country}${full_city}`;
}