import { Component } from "react";
import "./newstyle/filters.css";
import { Form, Input, Select, Button } from "antd";
import {universites} from "./../data.js"
import UnivirsityBox from "../component/UnivirsityBox.jsx";
class Universties extends Component {
  constructor(props) {
    super(props);
  }
  state = {};
  render() {
    return (
      <div className="filter-page universities-page">
        <div className="inner-container">
          <div className="filter-page-cover">
            <div className="cover-content">
              <video autoPlay loop muted playsInline className="video">
                <source
                  src={
                    "https://res.cloudinary.com/dhy5o5vdh/video/upload/v1731274002/133199549-academic-graduation-caps-throw_muqwej.mp4"
                  }
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="filter-sections">
              <div className="cfilter-controls">
                <Form layout="">
                  <Form.Item name="f1" label="اسم الجامعة">
                    <Input
                      placeholder="قم بالبحث باسم الجامعة"
                      style={{
                        width: "100%",
                      }}
                    />
                  </Form.Item>
                  <Form.Item label="العنوان">
                    <Input
                      style={{
                        width: "100%",
                      }}
                      placeholder="قم بالبحث بعنوان الجامعة"
                    />
                  </Form.Item>
                  <Form.Item
                  //   label="المنطقة"
                  >
                    <Select
                      placeholder="اختر المنطقة"
                      style={{
                        width: "100%",
                      }}
                    >
                      <Select.Option></Select.Option>
                      <Select.Option></Select.Option>
                      <Select.Option></Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item htmlFor="submit">
                    <Button>إبحث</Button>
                  </Form.Item>
                </Form>
              </div>
              <div className="filter-results">
                {universites?.map((uni,index)=>{
                    return <UnivirsityBox key={index} data={uni}/>
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Universties;
