import React, { useState } from "react";
import AwesomeSlider from "react-awesome-slider";
import "react-awesome-slider/dist/custom-animations/cube-animation.css";
import "react-awesome-slider/dist/custom-animations/fold-out-animation.css";
import "react-awesome-slider/dist/custom-animations/open-animation.css";
import "react-awesome-slider/dist/custom-animations/fall-animation.css";
import "react-awesome-slider/dist/custom-animations/scale-out-animation.css";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import slide1 from "../../assets/slider/slide1.jpg";
import slide2 from "../../assets/slider/slide2.jpg";
import slide3 from "../../assets/slider/slide3.jpg";
import "./auth.css";

import { Form, Input, Divider, Button } from "antd";
import { FcGoogle } from "react-icons/fc";
import { BsFacebook, BsTwitterX } from "react-icons/bs";
import { connect } from "react-redux";
import { googleProvider, auth, xProvider, facebookProvider } from "../../firebase";
import {
  authenticateFailed,
  authenticateRequest,
  authenticateSuccess,
  saveUserAuthinfo,
} from "../../redux/actions/authActions";
import {
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
const AutoplaySlider = withAutoplay(AwesomeSlider);

const Signin = (props) => {
  const onFinish = (values) => {
    console.log(values);
    const {email,password}= values
    loginWithEmailAndPassword(email,password)
  };

  const signInWithGoogle = async () => {
    console.log("TRYING TO GOOGLE AUTHENTICATE");
    props.tryAuthenticate();
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const { user } = result;
      console.log(user);
      let authInfo = {
        userInfo: user,
        isLoggedIn: true,
        authLoading: false,
        authError: null,
      };
      props.authencticateSuccess(user);
      saveUserAuthinfo(authInfo);
      return user;
    } catch (error) {
      console.log(error);
      let authInfo = {
        userInfo: null,
        isLoggedIn: false,
        authLoading: false,
        authError: error,
      };
      saveUserAuthinfo(authInfo);
      props.authencticateFailed(error);
    }
  };
  const loginWithEmailAndPassword = async (email,password) => {
    console.log("TRYING TO Email & Password AUTHENTICATE");
    props.tryAuthenticate();
    try {
     const result =await  signInWithEmailAndPassword(auth,email,password);
     const { user } = result;
      console.log(user);
      let authInfo = {
        userInfo: user,
        isLoggedIn: true,
        authLoading: false,
        authError: null,
      };
      props.authencticateSuccess(user);
      saveUserAuthinfo(authInfo);
      return user;
    } catch (error) {
      console.log(error);
      let authInfo = {
        userInfo: null,
        isLoggedIn: false,
        authLoading: false,
        authError: error,
      };
      saveUserAuthinfo(authInfo);
      props.authencticateFailed(error);
    }
  };
  const signInWithFacebook = async () => {
    console.log("TRYING TO FACEBOOK AUTHENTICATE");
    props.tryAuthenticate();
    try {
      const result = await signInWithPopup(auth, facebookProvider);
      const { user } = result;
      console.log(user);
      let authInfo = {
        userInfo: user,
        isLoggedIn: true,
        authLoading: false,
        authError: null,
      };
      props.authencticateSuccess(user);
      saveUserAuthinfo(authInfo);
      return user;
    } catch (error) {
      console.log(error);
      let authInfo = {
        userInfo: null,
        isLoggedIn: false,
        authLoading: false,
        authError: error,
      };
      saveUserAuthinfo(authInfo);
      props.authencticateFailed(error);
    }
  };

  const signInWithX = async () => {
    console.log("TRYING TO X AUTHENTICATE");
    props.tryAuthenticate();
    try {
      const result = await signInWithPopup(auth, xProvider);
      const { user } = result;
      console.log(user);
      let authInfo = {
        userInfo: user,
        isLoggedIn: true,
        authLoading: false,
        authError: null,
      };
      props.authencticateSuccess(user);
      saveUserAuthinfo(authInfo);
      return user;
    } catch (error) {
      console.log(error);
      let authInfo = {
        userInfo: null,
        isLoggedIn: false,
        authLoading: false,
        authError: error,
      };
      saveUserAuthinfo(authInfo);
      props.authencticateFailed(error);
    }
  };

  return (
    <div className="auth-page signin-page relative">
      <div className="sliderr-wrapper">
        <AutoplaySlider 
        organicArrows={false}
        play={true} animation="cubeAnimation" interval={20000}
        bullets={false}
        
        >
          <div
            className="slider-slide"
            style={{
              backgroundImage: `url("${slide1}")`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          ></div>
          <div
            className="slider-slide"
            style={{
              backgroundImage: `url("${slide2}")`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          ></div>
          <div
            className="slider-slide"
            style={{
              backgroundImage: `url("${slide3}")`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          ></div>
        </AutoplaySlider>
      </div>
      <div className="auth-form absolute">
        <Form onFinish={onFinish} size="large" layout="vertical">
          <Form.Item name="email" label="البريد الالكتروني">
            <Input type="email" placeholder="البريد الالكتروني" />
          </Form.Item>
          <Form.Item name="password" label="كلمة المرور">
            <Input type="password" placeholder="كلمةالمرور" />
          </Form.Item>

          <Form.Item>
            <Button htmlType="submit" className="authenticate-btn">دخول</Button>
          </Form.Item>
          <Divider>أو بواسطة</Divider>
          <div className="auth-btns">
            <button
              onClick={signInWithGoogle}
            >
              <FcGoogle />{" "}
            </button>
            <button onClick={signInWithFacebook}>
              <BsFacebook />
            </button>
            <button  onClick={signInWithX}>
              <BsTwitterX />
            </button>
          </div>
          <div className="form-tail">
            <p>
              ليس لديك حساب ؟
              <b>
                <a href="/register">انشاء حساب</a>
              </b>
            </p>
          </div>
        </Form>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  tryAuthenticate: () => dispatch(authenticateRequest()),
  authencticateSuccess: (user) => dispatch(authenticateSuccess(user)),
  authencticateFailed: (error) => dispatch(authenticateFailed(error)),
});
const mapStateToProps = (state) => {
  return {
    auth: state.authReducer,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Signin);
