import { initializeApp } from "firebase/app";

import {
  FacebookAuthProvider,
  getAuth,
  GoogleAuthProvider,
  TwitterAuthProvider,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCQTfmlwky0UAqEf-iCAy1ATSZxqdXyiKY",
  authDomain: "skools-a1e3f.firebaseapp.com",
  projectId: "skools-a1e3f",
  storageBucket: "skools-a1e3f.appspot.com",
  messagingSenderId: "147486731272",
  appId: "1:147486731272:web:82ee076b4b674c3e2175bd",
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

export const googleProvider = new GoogleAuthProvider();
export const xProvider = new TwitterAuthProvider();
export const facebookProvider = new FacebookAuthProvider();

