import { Component } from "react";

class UnivirsityBox extends Component {
  constructor({props}) {
    super(props);
    this.state={
        
    }
  }
//   state = {};
  render() {
    return (
      <div className="result-box university-box">
        <div className="box-container">
          <div className="box-content">
            <div className="result-image"></div>
            <div className="result-desc">
                <h1>{this.props?.data?.name}</h1>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UnivirsityBox;
