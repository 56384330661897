// import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "@coreui/coreui/dist/css/coreui.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import configureStore from "./redux/reducers/store";
import { Provider } from "react-redux";

const store = configureStore();
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>

  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
  // </React.StrictMode>
);
