import React, { Component } from "react";
import "./profile.css";
import { IoLocation } from "react-icons/io5";
import { Tabs } from "antd";
const items = [
  {
    key: "1",
    label: "عن الجامعة ",
    children: "معلومات عن الجـامعة",
  },
  {
    key: "2",
    label: "الأقسام والكليات",
    children: "عرض تفصيلي عن أقسـام وكليات الجامعة ",
  },
  {
    key: "3",
    label: "تواصل معنا",
    children: "أرسل الشكوي والاستفسار",
  },
];
class TestProfilePage extends Component {
  constructor(props) {
    super(props);
  }
  state = {};
  onChange = (key) => {
    console.log(key);
  };

  render() {
    return (
      <div className="profile-entity-page">
        <div className="profile-container">
          <div className="profile-inner">
            <div
              className="profile-cover"
              style={{
                backgroundImage: `url("https://res.cloudinary.com/dhy5o5vdh/image/upload/v1731272608/pexels-pixabay-256490_fthpo8.jpg")`,
              }}
            ></div>
            <div className="profile-head">
              <div className="profile-left">
                <div
                  className="avatar"
                  style={{
                    backgroundImage: `url("https://res.cloudinary.com/dhy5o5vdh/image/upload/v1731272613/pexels-szymon-shields-1503561-11210303_x5kzst.jpg")`,
                  }}
                ></div>
                <div className="profile-text">
                  <p className="name">الاسم هنـــا</p>
                  <p className="address_0">
                    القاهرة, مصر
                    <span>
                      <IoLocation />
                    </span>
                  </p>
                </div>
              </div>
              <div className="profile-btns">
                <button> متابعة</button>
                <button>أرسل استفسـارك</button>
                <button className="apply-now"> تقدم بطلب التقسيط الآن</button>
              </div>
            </div>
            <div className="profile-content mt-5">
              <Tabs
                defaultActiveKey="1"
                items={items}
                onChange={this.onChange}
                centered
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TestProfilePage;
