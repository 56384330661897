import React, { Component } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "./services.css";
import { Autoplay,EffectCoverflow } from 'swiper/modules';

class ServicesSection extends Component {
  constructor(props) {
    super(props);
  }
  state = {};
  render() {
    return (
      <div className="applications-services-section">
        <div className="services-section-head">
          <p className="services-title">خدمات مميزة</p>
          <div className="swiper-content">
            <Swiper
              slidesPerView={1.4}
              centeredSlides={true}
                effect="coverflow"
                coverflowEffect={{
                  rotate: 50,
                  stretch: 0,
                  depth: 100,
                  modifier: 1,
                  slideShadows: true,
                }}

                modules={[EffectCoverflow, Autoplay]}
            //   modules={[Autoplay]}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
              dir="rtl"
              pagination={{
                type: "fraction",
              }}
              navigation={true}
              spaceBetween={35}
              grabCursor={true}
              loop={true}
              className="mySwiper"
              onSlideChange={() => console.log("slide change")}
              onSwiper={(swiper) => console.log(swiper)}
            >
              <SwiperSlide>
                <div className="slide-inner-content">
                  <div
                    className="slide-img"
                    style={{
                      backgroundImage: `url("https://res.cloudinary.com/dhy5o5vdh/image/upload/v1731966092/pexels-artstel-4936021_sihby0.jpg")`,
                    }}
                  ></div>
                  <div className="slide-text">
                    <h5 className="vacancy-name">اسم المكان</h5>
                    <p className="vacancy-desc">
                      لوريم ايبسوم هو نموذج افتراضي يوضع في التصاميم لتعرض على
                      العميل ليتصور طريقه وضع النصوص بالتصاميم سواء كانت تصاميم
                      مطبوعه … بروشور او فلاير على سبيل المثال … او نماذج مواقع
                      انترنت …
                    </p>
                    <button className="service-btn">
                        اعرف المزيد
                    </button>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="slide-inner-content">
                  <div
                    className="slide-img"
                    style={{
                      backgroundImage: `url("https://res.cloudinary.com/dhy5o5vdh/image/upload/v1731965659/pexels-pavel-danilyuk-8422207_tetysj.jpg")`,
                    }}
                  ></div>
                  <div className="slide-text">
                    <h5 className="vacancy-name">اسم المكان</h5>
                    <p className="vacancy-desc">
                      لوريم ايبسوم هو نموذج افتراضي يوضع في التصاميم لتعرض على
                      العميل ليتصور طريقه وضع النصوص بالتصاميم سواء كانت تصاميم
                      مطبوعه … بروشور او فلاير على سبيل المثال … او نماذج مواقع
                      انترنت …
                    </p>
                    <button className="service-btn">
                        اعرف المزيد
                    </button>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="slide-inner-content">
                  <div
                    className="slide-img"
                    style={{
                      backgroundImage: `url("https://res.cloudinary.com/dhy5o5vdh/image/upload/v1731965810/pexels-yankrukov-8612955_d3hnuj.jpg")`,
                    }}
                  ></div>
                  <div className="slide-text">
                    <h5 className="vacancy-name">اسم المكان</h5>
                    <p className="vacancy-desc">
                      لوريم ايبسوم هو نموذج افتراضي يوضع في التصاميم لتعرض على
                      العميل ليتصور طريقه وضع النصوص بالتصاميم سواء كانت تصاميم
                      مطبوعه … بروشور او فلاير على سبيل المثال … او نماذج مواقع
                      انترنت …
                    </p>
                    <button className="service-btn">
                        اعرف المزيد
                    </button>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="slide-inner-content">
                  <div
                    className="slide-img"
                    style={{
                      backgroundImage: `url("https://res.cloudinary.com/dhy5o5vdh/image/upload/v1731965172/pexels-kimmi-jun-201206578-18506738_hgjkqs.jpg")`,
                    }}
                  ></div>
                  <div className="slide-text">
                    <h5 className="vacancy-name">اسم المكان</h5>
                    <p className="vacancy-desc">
                      لوريم ايبسوم هو نموذج افتراضي يوضع في التصاميم لتعرض على
                      العميل ليتصور طريقه وضع النصوص بالتصاميم سواء كانت تصاميم
                      مطبوعه … بروشور او فلاير على سبيل المثال … او نماذج مواقع
                      انترنت …
                    </p>
                    <button className="service-btn">
                        اعرف المزيد
                    </button>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="slide-inner-content">
                  <div
                    className="slide-img"
                    style={{
                      backgroundImage: `url("https://images.pexels.com/photos/14608917/pexels-photo-14608917.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load")`,
                    }}
                  ></div>
                  <div className="slide-text">
                    <h5 className="vacancy-name">اسم المكان</h5>
                    <p className="vacancy-desc">
                      لوريم ايبسوم هو نموذج افتراضي يوضع في التصاميم لتعرض على
                      العميل ليتصور طريقه وضع النصوص بالتصاميم سواء كانت تصاميم
                      مطبوعه … بروشور او فلاير على سبيل المثال … او نماذج مواقع
                      انترنت …
                    </p>
                    <button className="service-btn">
                        اعرف المزيد
                    </button>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="slide-inner-content">
                  <div
                    className="slide-img"
                    style={{
                      backgroundImage: `url("https://res.cloudinary.com/dhy5o5vdh/image/upload/v1731271768/pexels-jonathanborba-12876406_1_h6a22s.jpg")`,
                    }}
                  ></div>
                  <div className="slide-text">
                    <h5 className="vacancy-name">اسم المكان</h5>
                    <p className="vacancy-desc">
                      لوريم ايبسوم هو نموذج افتراضي يوضع في التصاميم لتعرض على
                      العميل ليتصور طريقه وضع النصوص بالتصاميم سواء كانت تصاميم
                      مطبوعه … بروشور او فلاير على سبيل المثال … او نماذج مواقع
                      انترنت …
                    </p>
                    <button className="service-btn">
                        اعرف المزيد
                    </button>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="slide-inner-content">
                  <div
                    className="slide-img"
                    style={{
                      backgroundImage: `url("https://res.cloudinary.com/dhy5o5vdh/image/upload/v1731965206/pexels-ifangfang-3864594_dv1bh1.jpg")`,
                    }}
                  ></div>
                  <div className="slide-text">
                    <h5 className="vacancy-name">اسم المكان</h5>
                    <p className="vacancy-desc">
                      لوريم ايبسوم هو نموذج افتراضي يوضع في التصاميم لتعرض على
                      العميل ليتصور طريقه وضع النصوص بالتصاميم سواء كانت تصاميم
                      مطبوعه … بروشور او فلاير على سبيل المثال … او نماذج مواقع
                      انترنت …
                    </p>
                    <button className="service-btn">
                        اعرف المزيد
                    </button>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="slide-inner-content">
                  <div
                    className="slide-img"
                    style={{
                      backgroundImage: `url("https://res.cloudinary.com/dhy5o5vdh/image/upload/v1731965172/pexels-kimmi-jun-201206578-18506750_h2lsnw.jpg")`,
                    }}
                  ></div>
                  <div className="slide-text">
                    <h5 className="vacancy-name">اسم المكان</h5>
                    <p className="vacancy-desc">
                      لوريم ايبسوم هو نموذج افتراضي يوضع في التصاميم لتعرض على
                      العميل ليتصور طريقه وضع النصوص بالتصاميم سواء كانت تصاميم
                      مطبوعه … بروشور او فلاير على سبيل المثال … او نماذج مواقع
                      انترنت …
                    </p>
                    <button className="service-btn">
                        اعرف المزيد
                    </button>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
          <button className="service-btn border-gradient">تصفح الكل</button>
        </div>
      </div>
    );
  }
}

export default ServicesSection;
