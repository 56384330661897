import * as actions from "../constants";
import axios from "axios";
// import {toast, Flip } from 'react-toastify';

// export const
export const setBasicInfo = (info) => ({
  type: actions.ADD_BASIC_INFO_STEP,
  payload: info,
});
export const setChildrenInfo = (info) => ({
  type: actions.ADD_CHILDREN_INFO_STEP,
  payload: info,
});
export const setTaqseetDocument = (docs) => ({
  type: actions.ADD_TAQSEET_DOCUMENT_STEO,
  payload: docs,
});

export const goToStep = (step) => ({
  type: actions.GO_TO_STEP,
  payload: step,
});

export const authenticateRequest = () => ({
  type: actions.AUTHENTICATION_REQUEST,
});
export const authenticateSuccess = (user) => ({
  type: actions.AUTHENTICATION_SUCCESS,
  payload: user,
});
export const authenticateFailed = (error) => ({
  type: actions.AUTHENTICATION_FAILED,
  payload: error,
});
export const logout = () => ({
  type: actions.LOGOUT,
});

export const saveUserAuthinfo = (authInfo) => {
  localStorage.setItem("authState", JSON.stringify(authInfo));
};

export const authenticateRequestActionCreator = () => {
  authenticateRequest();
};
export const authenticateSuccessActionCreator = (user) => {
  authenticateSuccess(user);
};
export const authenticateFailedActionCreator = (error) => {
  authenticateFailed(error);
};

export const setParentInfo = (info) => ({
  type: actions.ADD_PARENT_INFO_STEP,
  payload: info,
});

export const setSchoolInfo = (info) => ({
  type: actions.ADD_SCHOOL_INFO_STEP,
  payload: info,
});

export const uploadParentDocuments = (docs) => ({
  type: actions.UPLOAD_PARENT_DOCUMENTS_STEP,
  payload: docs,
});
export const addChildernInfo = (info) => ({
  type: actions.ADD_CHILDREN_INFO_STEP,
  payload: info,
});

export const setDocumentInfoSrcs = (info) => ({
  type: actions.UPLOAD_PARENT_DOCUMENTS_STEP,
  payload: info,
});
