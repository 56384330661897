import React, { Component } from "react";
import "react-awesome-slider/dist/styles.css";

import AwesomeSlider from "react-awesome-slider";
import "react-awesome-slider/dist/custom-animations/cube-animation.css";
import "react-awesome-slider/dist/custom-animations/fold-out-animation.css";
import "react-awesome-slider/dist/custom-animations/open-animation.css";
import "react-awesome-slider/dist/custom-animations/fall-animation.css";
import "react-awesome-slider/dist/custom-animations/scale-out-animation.css";
import "./test.css";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import slide1 from "../../assets/slider/slide1.jpg"
import slide2 from "../../assets/slider/slide2.jpg"
import slide3 from "../../assets/slider/slide3.jpg"

const AutoplaySlider = withAutoplay(AwesomeSlider);
class Sliderr extends Component {
  constructor(props) {
    super(props);
  }
  state = {};
  render() {
    return (
      <>
        <div className="sliderr-wrapper">
          <AutoplaySlider
            play={true}
            animation="cubeAnimation"
            interval={10000}
          >
            <div
              className="slider-slide"
              style={{
                backgroundImage: `url("${slide1}")`,
                backgroundRepeat: "no-repeat",
                backgroundSize:"cover",
                backgroundPosition:"center"
              }}
            ></div>
            <div
              className="slider-slide"
              style={{
                backgroundImage: `url("${slide2}")`,
                backgroundRepeat: "no-repeat",
                backgroundSize:"cover",
                backgroundPosition:"center"
              }}
            ></div>
            <div
              className="slider-slide"
              style={{
                backgroundImage: `url("${slide3}")`,
                backgroundRepeat: "no-repeat",
                backgroundSize:"cover",
                backgroundPosition:"center"
              }}
            ></div>
          </AutoplaySlider>
        </div>
      </>
    );
  }
}

export default Sliderr;
