export const universites = [
  {
    name: "UNIVERSITY ONE",
    isFeatured: true,
    address: "5 Sall St, Alexandria",
    state: "Alexandria",
    startingAt: 100000,
    cover:" https://res.cloudinary.com/dhy5o5vdh/image/upload/v1731272613/pexels-szymon-shields-1503561-11210303_x5kzst.jpg",
    majors: [
      "Cyber Security",
      "Softaware Engineering",
      "Physiotherapy",
      "Medicine",
    ],
    media: [
      {
        src: "https://res.cloudinary.com/dhy5o5vdh/image/upload/v1731272613/pexels-szymon-shields-1503561-11210303_x5kzst.jpg",
      },
    ],
  },
  {
    name: "UNIVERSITY TWO",
    address: "5 Nine St , Ma'adi",
    isFeatured: false,
    state: "Cairo",
    startingAt: 200000,
    majors: ["Medicine","Dental Medicine","Dentistry","Arts","Law"],
    media: [
      {
        src: "https://res.cloudinary.com/dhy5o5vdh/image/upload/v1731272608/pexels-pixabay-256490_fthpo8.jpg",
      },
      {
        src: "",
      },
      {
        src: "",
      },
    ],
  },
  {
    name: "UNIVERSITY THREE",
    isFeatured: true,
    isInternationsl: true,
    address: "7 St, Fifth Settlement",
    state: "Cairo",
    startingAt: 500000,
    majors: ["Engineering", "Midicine", "Pharmacy", "Information Technology"],
    media: [
      {
        src: "https://res.cloudinary.com/dhy5o5vdh/image/upload/v1731272604/pexels-tahir-osman-109306362-17906775_hureei.jpg",
      },
    ],
  },
];

// const schools = [{}];

// const nursaries = [{}];

// const wedding_halls = [{}];
