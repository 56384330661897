import "./App.css";
// import Register from "./pages/auth/Register";
// import { useTranslation } from "react-i18next";
import "./i18n";
// import LanguageSelector from "./component/LanguageSelector";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import Homepage from "./pages/Homepage";
// import NewHomepage from "./pages/NewHomepage";
import FinalHomepage from "./pages/newstyle/FinalHomepage";
// import NavigationComponent from "./component/NavigationComponent";
import FlowWrapper from "./pages/installment-flow/FlowWrapper";
import Sliderr from "./pages/test/Sliderr";
import Signup from "./pages/auth/Signup";
import "react-awesome-slider/dist/styles.css";
import Signin from "./pages/auth/Signin";
import ProtectedRoute from "./component/customRoutes/ProtectedRoute";
import AuthRoute from "./component/customRoutes/AuthRoute";
import "./index.css"
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import NavigationMenu from "./pages/newstyle/NavigationMenu";
import Universties from "./pages/Universties";
import TestProfilePage from "./pages/TestProfilePage";
import ApplicationForm from "./pages/ApplicationForm";

function App() {
  return (
    <>
      {/* <NavigationComponent/> */}
      {/* <NavigationMenu/> */}
      <Router>
        <Routes>
          {/* <Route path="/" element={<Homepage />} /> */}
          <Route
            path="/"
            element={
              <ProtectedRoute>
                {/* <Homepage /> */}
                {/* <NewHomepage /> */}
                <FinalHomepage />
              </ProtectedRoute>
            }
          />
           <Route
            path="/universites"
            element={
                <Universties />
            }
          />
          <Route
            path="/register"
            element={
              <AuthRoute>
                <Signup />
              </AuthRoute>
            }
          />
          <Route
            path="/login"
            element={
              <AuthRoute>
                <Signin />
              </AuthRoute>
            }
          />
          <Route
            path="/profile"
            element={
             <TestProfilePage/>
            }
          />
           <Route
            path="/application"
            element={
             <ApplicationForm/>
            }
          />
          <Route path="/test" element={<Sliderr />} />
          <Route
            path="/installment-financing"
            element={
              <ProtectedRoute>
                <FlowWrapper />
              </ProtectedRoute>
            }
          />
        </Routes>
      </Router>
    </>
  );
}

export default App;
